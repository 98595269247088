@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --palette-primary-main: #00494e; /* dark green */

    --font-family-headline: 'Sofia Sans', sans-serif;
    --font-weight-headline-regular: 700;
    --font-weight-headline-bold: 900;

    --font-family-body: 'Sofia Sans', sans-serif;
    --font-weight-body-regular: 400;
    --font-weight-body-bold: 600;
}

@layer base {
    @font-face {
        font-family: 'Sofia Sans';
        src: url('/fonts/SofiaSansSemiCondensed-VariableFont_wght.ttf ') format('truetype');
    }
    body,
    p {
        @apply font-body;
        font-weight: var(--font-weight-body-regular);
    }

    h1 {
        @apply text-3xl;
        @apply font-headline;
        font-weight: var(--font-weight-headline-bold);
    }

    h2 {
        @apply text-xl;
        @apply font-headline;
        font-weight: var(--font-weight-headline-regular);
    }

    h3 {
        @apply text-base;
        @apply font-headline;
        font-weight: var(--font-weight-headline-regular);
    }
}

@layer components {
    .primary-gradient-bg {
        background: linear-gradient(180deg, rgba(94, 166, 152, 0.2) 5.92%, #ffffff 34.72%),
            url('/images/gradient-texture.png'), #f3f3f3;
        background-size: 100vw;
    }
}

@layer utilities {
    .h-header {
        height: 95px;
    }

    .bg-position-header {
        /****
       * I made this less than 95px because of the "scroll bounce" effect ... I thought that
       * revealing a bright white color too easily above the gradient texture looked bad.
       ***/
        background-position-y: 75px;
    }

    .pt-header {
        padding-top: 95px;
    }

    .left-footer-triangle {
        left: calc(50% - 15.5px);
    }
}
